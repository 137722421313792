// @use "common/fonts.scss";
// @use "common/normalize.scss";
// @use "common/base.scss";
// @use "common/theme.scss";
// @use "blocks/header.scss";
// @use "blocks/nav.scss";
// @use "blocks/main.scss";
// @use "blocks/footer.scss";
// @use "blocks/form.scss";

$primary: #cd3545;
$secondary: #757575;
$light: #F3F6F8;
$dark: #0C2B4B;
$font-family-base: 'Work Sans', sans-serif;
$body-color: $secondary;
$headings-color: $dark;
$headings-font-weight: 600;
$display-font-weight: 700;
$enable-responsive-font-sizes: true;
$border-radius: 0;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;
$link-decoration: none;
$enable-negative-margins: true;

@import "bootstrap/bootstrap";
@import "theme";
