/********** Template CSS **********/
:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --light: #{$light};
  --dark: #{$dark};
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-danger,
.btn.btn-outline-danger:hover {
  color: #fff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand,
.navbar a.btn {
  height: 80px
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: var(--dark);
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      border-top: 1px solid #eee;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
.carousel-caption {
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, .75);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--primary);
  border: 10px solid var(--primary);
}

.carousel-item img {
  max-height: 550px;
  object-fit: cover;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
      position: relative;
      min-height: 450px;
  }

  #header-carousel .carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

.page-header {
  background: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .75)), url(../img/jewelry-1.jpg) center center no-repeat;
  background-size: cover;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #999;
}


/*** Facts ***/
@media (min-width: 991.98px) {
  .facts {
      position: relative;
      margin-top: -75px;
      z-index: 1;
  }
}


/*** Courses ***/
.courses {
  min-height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(../img/jewelry-1.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.courses-item .courses-overlay {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  overflow: hidden;
  opacity: 0;
  transition: .5s;
}

.courses-item:hover .courses-overlay {
  height: 100%;
  opacity: 1;
}

.courses-card img {
  min-width: 100%;
}

/*** Promo ****/
.promo {
  min-height: 50vh;
  background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(../img/jewelry-1.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

/*** Team ***/
.team-items {
  margin: -.75rem;
}

.team-item {
  padding: .75rem;
}

.team-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #fff;
  transition: .5s;
  z-index: -1;
}

.team-item:hover::after {
  height: 100%;
  background: var(--primary);
}

.team-item .team-social {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .75);
  overflow: hidden;
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-social {
  height: 100%;
  opacity: 1;
}


/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid var(--primary);
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  width: 40px;
  height: 40px;
  background: var(--primary);
}

.testimonial-carousel .owl-item img {
  width: 150px;
  height: 150px;
}

/*** workphoto ***/
.workphoto-carousel .owl-dots {
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workphoto-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid var(--primary);
  transition: .5s;
}

.workphoto-carousel .owl-dot.active {
  width: 40px;
  height: 40px;
  background: var(--primary);
}

.workphoto-carousel .owl-item img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
}

/*** workphoto-single ***/
.workphoto-single-carousel .owl-dots {
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workphoto-single-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid var(--primary);
  transition: .5s;
}

.workphoto-single-carousel .owl-dot.active {
  width: 40px;
  height: 40px;
  background: var(--primary);
}

.workphoto-single-carousel .owl-item img {
  width: 450px;
  height: 450px;
  border-radius: 50%;
}

/*** timer ***/
#timer {
  font-family: Arial, sans-serif;
  font-size: 20px;
  color: #999;
  letter-spacing: -1px;
}
#timer span {
  font-size: 60px;
  color: #333;
  margin: 0 3px 0 15px;
}
#timer span:first-child {
  margin-left: 0;
}

/*** Footer ***/
.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: var(--light);
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}

.copyright {
  background: #092139;
}

.copyright a {
  color: var(--primary);
}

.copyright a:hover {
  color: var(--light);
}

/*** Стили для загрузки аватара пользователя ***/

.img-preview:root {
  --primary: color-mix(in srgb, red 60%, blueviolet);
}

.file-label {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.file-input {
  opacity: 0;
  width: 0px;
}

.img-preview {
  max-width: 250px;
  border-radius: 999px;
  outline: 3px solid var(--primary);
  cursor: pointer;
  transition: 200ms ease-in-out;

/* prevents uploaded image from distortion: */
  aspect-ratio: 1;
  object-fit: cover;
}

.img-preview:hover {
  outline: 8px solid var(--primary);
}

#toast {
  visibility: hidden;
  min-width: 100px;
  margin-left: -125px;
  background-color: var(--primary);
  color: whitesmoke;
  text-align: center;
  border-radius: 8px;
  padding: 12px;
  position: fixed;
  z-index: 1;
  right: 5%;
  top: 30px;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 30px; opacity: 0;}
}
